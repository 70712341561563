import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'nordic/page';
import { Head } from 'nordic/head';
import injectI18n from 'nordic/i18n/injectI18n';

import { EmptyStateWithIllustration } from '@andes/empty-state';

import NoResultsV3 from '@mshops-web-components/illustrations/NoResultsV3';
import { LOCALES_LIST } from '../../locales/localeList';

const View = ({ i18n, translations, siteId, lowEnd, deviceType, company, landingUrl, locale }) => {
  const preloadedState = {
    i18n, translations, siteId, lowEnd, deviceType, company, landingUrl, locale,
  };
  return (
    <Page
      name="notFound"
      state={preloadedState}
      className="shop-not-found"
    >
      <Head>
        <title>{i18n.gettext('Mercado Shops')}</title>
      </Head>
      <EmptyStateWithIllustration
        title={i18n.gettext('Parece que esta página no existe')}
        description=""
        action={{
          text: i18n.gettext('Sácame de aquí'),
          href: landingUrl,
        }}
        asset={<NoResultsV3 className="shop-not-found__illustration" />}
      />
    </Page>
  );
};

View.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
  translations: PropTypes.shape({}),
  lowEnd: PropTypes.bool,
  deviceType: PropTypes.string,
  company: PropTypes.string,
  landingUrl: PropTypes.string.isRequired,
  locale: PropTypes.oneOf(LOCALES_LIST).isRequired,
};

View.defaultProps = {
  translations: {},
  lowEnd: false,
  deviceType: null,
  company: null,
};

export default injectI18n(View);
